/* eslint-disable */
module.exports = {
    name: "bca_klikbca",
    description: "KlikBCA",
    image: "https://ik.imagekit.io/powerbiz/utils/klikbca.png",
    instructions  : {
        "KlikBCA": [
            `Kunjungi situs web KlikBCA <strong>www.klikbca.com.</strong>`,
            `Login menggunakan userID KlikBCA Anda.`,
            `Pilih Menu Pembayaran e-commerce.`,
            `Pilih Menu Pembayaran e-commerce.`,
            `Pilih Nama Perusahaan.`,
            `Klik Lanjutkan.`,
            `Pilih transaksi yang ingin Anda bayar dan pilih lanjutkan.`,
            `Konfirmasikan kembali pembayaran dengan memasukkan kunci token dan pilih kirim/lanjutkan.`
        ]
    }
}