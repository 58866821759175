/* eslint-disable */
const companyCode = "70018";
const companyName = "SPRINT";

module.exports = {
  name: "mandiri_va",
  description: "Mandiri Virtual Account",
  image: "https://ik.imagekit.io/powerbiz/utils/mandiri.png",
  instructions: {
    "ATM Mandiri": [
      "Masukkan kartu ATM dan PIN",
      "Pilih Menu <strong>Bayar/Beli</strong>",
      "Pilih menu <strong>Lainnya</strong>, hingga menemukan menu <strong>Multipayment</strong>",
      `Masukkan kode perusahaan ${companyName} <strong>${companyCode}</strong>, lalu pilih Benar`,
      "Masukkan <strong>Nomor Virtual Account</strong>, lalu pilih tombol Benar",
      "Masukkan Angka <strong>1</strong> untuk memilih tagihan, lalu pilih tombol Ya",
      "Akan muncul konfirmasi pembayaran, lalu pilih tombol Ya",
      "Simpan struk sebagai bukti pembayaran anda"
    ],
    "Internet Banking Mandiri": [
      `Login ke <strong>iBanking Mandiri</strong>, pilih <strong>Pembayaran</strong>, pilih <strong>Multi Payment</strong>, lalu pilih <strong>penyedia jasa (${companyName}).</strong>`,
      `Masukkan "Nomor Virtual Account" dan "Nominal" yang akan dibabayarkan , lalu pilih Lanjut`,
      `Setelah muncul tagihan, pilih Konfirmasi`,
      `Masukkan PIN / challange code token`,
      `Transaksi selesai, simpan bukti bayar anda`
    ]
  }
};
