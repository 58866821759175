<template>
  <a-card style="border-radius: 10px">
    <div class="col" style="font-size: 24px;">
      Detail Pesanan
    </div>
    <a-form :form="form">
      <div class="col mt-5">
        <div class="row">
          <div class="col-12 col-md-12">
            <PONumber
              :form="form"
              @updateVoucher="updateVoucher"
              @updateInitialPONumber="updateInitialPONumber"
              id="po-number"
            />
          </div>
        </div>
      </div>
      <a-tabs />
      <div class="col mt-2">
        <div class="row">
          <div class="col-12 col-md-12">
            <ShippingDetail
              :courier="courier"
              @updateCourier="updateCourier"
              @updateShippAddress="updateShippAddress"
              :form="form"
              id="shipping-detail"
            />
          </div>
        </div>
      </div>
      <a-tabs />
      <div class="col mt-2">
        <div class="row">
          <div class="col-12 col-md-12">
            <BillingData
              :form="form"
              id="billing-data"
              @updateBillAddress="updateBillAddress"
              @updateIsUseBillingSameShipping="updateIsUseBillingSameShipping"
            />
          </div>
        </div>
      </div>
      <a-tabs />
      <div class="col mt-2">
        <div class="row">
          <div class="col-12 col-md-12">
            <PaymentMethod
              :payment="payment"
              :form="form"
              @updateSelectedPayment="updateSelectedPayment"
              id="payment-method"
            />
          </div>
        </div>
      </div>
      <a-tabs />
      <div class="col mt-2">
        <a-tabs :default-active-key="tabKey" @change="handleChangeTab">
          <a-tab-pane key="Pesanan" tab="Pesanan" />
          <!-- <a-tab-pane key="Penerimaan Barang" tab="Penerimaan Barang" /> -->
          <!-- <a-tab-pane key="Pesanan Selesai" tab="Pesanan Selesai" /> -->
        </a-tabs>
      </div>
      <div class="col mb-5" v-if="tabKey === 'Penerimaan Barang'">
        <ConfirmGR/>
      </div>
      <div class="col">
        <Product
          id="product"
          @updateProduct="updateProduct"
          :outOfStock="outOfStock"
          :tabKey="tabKey"
        />
      </div>
      <div class="mt-5 d-flex justify-content-md-end">
        <BillingTotal
          :products="products"
          :selectedCourier="selectedCourier"
          :voucher="voucher"
          @updateRate="updateRate"
          @updateBillingData="updateBillingData"
          class="col-6"
        />
      </div>
      <div class="col mt-5 d-flex justify-content-md-end" v-if="tabKey === 'Pesanan'">
        <a-button @click.prevent="createPurchase" type="primary"> Konfirmasi Pesanan </a-button>
      </div>
    </a-form>
    <div class="vld-parent">
      <loading-overlay
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        :height="30"
        :width="30"
      ></loading-overlay>
    </div>
  </a-card>
</template>

<script>
import { mapState } from 'vuex'
import ShippingDetail from '@/components/Purchase/update/ShippingDetail'
import PaymentMethod from '@/components/Purchase/update/PaymentMethod'
import Product from '@/components/Purchase/update/Product'
import BillingTotal from '@/components/Purchase/update/BillingTotal'
import ConfirmGR from '@/components/Purchase/update/ConfirmGR'
import PONumber from '@/components/Purchase/update/PONumber'
import BillingData from '@/components/Purchase/update/BillingData'
import LoadingOverlay from 'vue-loading-overlay'

export default {
  name: 'component.purchase.create',
  components: {
    ShippingDetail,
    PaymentMethod,
    Product,
    BillingTotal,
    ConfirmGR,
    PONumber,
    LoadingOverlay,
    BillingData,
  },
  data: function () {
    return {
      products: [],
      form: this.$form.createForm(this),
      selectedCourier: null,
      selectedPayment: null,
      billingData: {},
      rate: null,
      tabKey: 'Pesanan',
      isNewBillAddress: false,
      isNewShippAddress: false,
      initialPONumber: '',
      outOfStock: [],
      voucher: null,
      isBillingSameShipping: true, // null, false, true
    }
  },
  computed: {
    ...mapState(['purchase', 'data', 'account', 'app']),
    courier () {
      return this.purchase.courier
    },
    payment () {
      return this.purchase.paymentMethod
    },
    loading () {
      return this.purchase.loading
    },
    filterProduct () {
      const products = this.products.filter(item => {
        if (item) {
          return item
        }
      })
      return products
    },
  },
  // watch: {
  //   payment: {
  //     immediate: true,
  //     handler () {
  //       const cc = this.payment.find(payment => payment.methode_name === 'midtrans_cc')
  //       const { set } = this.$meta().addApp('payment-libs')

  //       if (cc) {
  //         set({
  //           script: [
  //             {
  //               vmid: 'midtrans-libs',
  //               src: 'https://api.midtrans.com/v2/assets/js/midtrans-new-3ds.min.js',
  //               'data-environment': 'sandbox',
  //               'data-client-key': '913874985',
  //             },
  //           ],
  //         })
  //       }
  //     },
  //   },
  // },
  methods: {
    isProductsCompleted () {
      let isProductsCompleted = true
      if (!this.filterProduct.length) {
        isProductsCompleted = false
      } else {
        this.filterProduct.forEach(item => {
          if (!item || !item.uom || (!item.catalog_id && !item.product_id && !item.unit && !item.quantity)) {
            isProductsCompleted = false
          }
        })
      }

      return isProductsCompleted
    },
    handleChangeTab (value) {
      this.tabKey = value
    },
    updateProduct (value) {
      this.products = value
    },
    updateCourier (value) {
      this.selectedCourier = value
    },
    updateSelectedPayment (value) {
      this.selectedPayment = value
    },
    updateRate (value) {
      this.rate = value
    },
    updateBillingData (value) {
      this.billingData = value
    },
    updateShippAddress (value) {
      this.isNewShippAddress = value
    },
    updateBillAddress (value) {
      this.isNewBillAddress = value
    },
    updateVoucher (value) {
      this.voucher = value
    },
    getCityIdBilling (value) {
      const findData = this.purchase.cityListBilling.find(item => item.label === value)
      return Number(findData.value)
    },
    getAreaIdBilling (value) {
      const findData = this.purchase.areaListBilling.find(item => item.label === value)
      return Number(findData.value)
    },
    getCityIdShipping (value) {
      const findData = this.purchase.cityListShipping.find(item => item.label === value)
      return Number(findData.value)
    },
    getAreaIdShipping (value) {
      const findData = this.purchase.areaListShipping.find(item => item.label === value)
      return Number(findData.value)
    },
    updateInitialPONumber (value) {
      this.initialPONumber = value
    },
    updateIsUseBillingSameShipping (value) {
      this.isBillingSameShipping = value
    },
    createPurchase () {
      this.form.validateFieldsAndScroll((err, values) => {
        if (err) return
        /**
         * TODO: add if distributor type: before delivery
         */
        if (!this.selectedPayment) {
          const element = document.getElementById('payment-method')
          element.scrollIntoView({
            behavior: 'smooth',
          })

          return this.$notification.warning({
            message: 'Please select payment method',
          })
        }

        if (!this.isProductsCompleted()) {
          const element = document.getElementById('product')
          element.scrollIntoView({
            behavior: 'smooth',
          })
          return this.$notification.warning({
            message: 'Please completed form',
          })
        }

        this.outOfStock = []

        const transaction_data = {
          payment_methode: {
            id: this.selectedPayment.id,
            methode_title: this.selectedPayment.meta.description,
            methode_name: this.selectedPayment.methode_name,
            group_id: this.selectedPayment.group_id,
          },
          sub_total: this.billingData.subTotal,
          shipping_cost: 0, // hardcode
          insurance_cost: 0, // hardcode
          gross_amount: this.billingData.total,
          voucher_value: 0, // hardcode
          total: this.billingData.total,
          currency_id: 111,
          notes: this.form.getFieldValue('note') || '',
          delivery_date: this.$moment(this.form.getFieldValue('date')).format('X'),
        }

        if (this.voucher) {
          transaction_data.voucher_value = this.billingData.voucher_value
          transaction_data.vouchers = [
            {
              code: this.billingData.voucher.code,
              type: this.billingData.voucher.type,
            },
          ]
        }

        const shipping_data = {
          id: !this.isNewShippAddress ? this.purchase.lastShippingAddress.uuid : '0',
          first_name: !this.isNewShippAddress ? this.purchase.lastShippingAddress.first_name : values.shipping_first_name,
          name: !this.isNewShippAddress ? this.purchase.lastShippingAddress.name : values.shipping_name,
          phone: !this.isNewShippAddress ? this.purchase.lastShippingAddress.phone : values.shipping_phone,
          email: !this.isNewShippAddress ? this.purchase.lastShippingAddress.email : values.shipping_email,
          address1: !this.isNewShippAddress ? this.purchase.lastShippingAddress.address_line1 : values.shipping_address1,
          codepos: !this.isNewShippAddress ? +this.purchase.lastShippingAddress.codepos : Number(values.shipping_codepos),
          city_id: !this.isNewShippAddress ? this.purchase.lastShippingAddress.city_id : this.getCityIdShipping(values.shipping_city_id),
          area_id: !this.isNewShippAddress ? this.purchase.lastShippingAddress.area_id : this.getAreaIdShipping(values.shipping_area_id),
          country_id: !this.isNewBillAddress ? this.purchase.lastBillingAddress.country_id : values.shipping_country_id === 'Indonesia' ? 228 : 228,
        }

        const billing_data = {
          // id: !this.isNewBillAddress ? this.purchase.lastBillingAddress.uuid : '0',
          // first_name: !this.isNewBillAddress ? this.purchase.lastBillingAddress.first_name : values.billing_first_name,
          // name: !this.isNewBillAddress ? this.purchase.lastBillingAddress.name : values.billing_name,
          // phone: !this.isNewBillAddress ? this.purchase.lastBillingAddress.phone : values.billing_phone,
          // email: !this.isNewBillAddress ? this.purchase.lastBillingAddress.email : values.billing_email,
          // address1: !this.isNewBillAddress ? this.purchase.lastBillingAddress.address_line1 : values.billing_address1,
          // codepos: !this.isNewBillAddress ? +this.purchase.lastBillingAddress.codepos : Number(values.billing_codepos),
          // city_id: !this.isNewBillAddress ? this.purchase.lastBillingAddress.city_id : this.getCityIdBilling(values.billing_city_id),
          // area_id: !this.isNewBillAddress ? this.purchase.lastBillingAddress.area_id : this.getAreaIdBilling(values.billing_area_id),
          // country_id: !this.isNewBillAddress ? this.purchase.lastBillingAddress.country_id : values.shipping_country_id === 'Indonesia' ? 228 : 228,

          id: this.isBillingSameShipping && this.isNewBillAddress ? '0' : !this.isNewBillAddress ? this.purchase.lastBillingAddress.uuid : '0',
          first_name: this.isBillingSameShipping && this.isNewBillAddress ? shipping_data.first_name : !this.isNewBillAddress ? this.purchase.lastBillingAddress.first_name : values.billing_first_name,
          name: this.isBillingSameShipping && this.isNewBillAddress ? shipping_data.name : !this.isNewBillAddress ? this.purchase.lastBillingAddress.name : values.billing_name,
          phone: this.isBillingSameShipping && this.isNewBillAddress ? shipping_data.phone : !this.isNewBillAddress ? this.purchase.lastBillingAddress.phone : values.billing_phone,
          email: this.isBillingSameShipping && this.isNewBillAddress ? shipping_data.email : !this.isNewBillAddress ? this.purchase.lastBillingAddress.email : values.billing_email,
          address1: this.isBillingSameShipping && this.isNewBillAddress ? shipping_data.address1 : !this.isNewBillAddress ? this.purchase.lastBillingAddress.address_line1 : values.billing_address1,
          codepos: this.isBillingSameShipping && this.isNewBillAddress ? shipping_data.codepos : !this.isNewBillAddress ? +this.purchase.lastBillingAddress.codepos : Number(values.billing_codepos),
          city_id: this.isBillingSameShipping && this.isNewBillAddress ? shipping_data.city_id : !this.isNewBillAddress ? this.purchase.lastBillingAddress.city_id : this.getCityIdBilling(values.billing_city_id),
          area_id: this.isBillingSameShipping && this.isNewBillAddress ? shipping_data.area_id : !this.isNewBillAddress ? this.purchase.lastBillingAddress.area_id : this.getAreaIdBilling(values.billing_area_id),
          country_id: this.isBillingSameShipping && this.isNewBillAddress ? shipping_data.country_id : !this.isNewBillAddress ? this.purchase.lastBillingAddress.country_id : values.shipping_country_id === 'Indonesia' ? 228 : 228,
        }

        const warehouse_data = {
          id: this.rate.warehouse_id,
          is_selfpicked: false,
        }

        const courier = {
          name: this.rate.name,
          service: {
            code: this.rate.service.code,
            name: this.rate.service.name,
          },
          price: 0, // temporary
          compulsory_insurance: this.rate.compulsory_insurance,
          insurance_price: 0, // temporary
          weight: this.rate.weight,
          value: this.rate.value,
          id: this.rate.id,
          origin_id: this.rate.origin_id,
          f_insurance: this.selectedCourier.insurance,
        }

        const po_items = this.filterProduct
        this.$store.dispatch('purchase/CREATEPO', {
          warehouse_data,
          transaction_data,
          po_items,
          courier,
          shipping_data,
          billing_data,
          po_number: this.form.getFieldValue('po_number'),
          is_po_generator: this.form.getFieldValue('po_number') === this.initialPONumber,
        })
          .then((data) => {
            this.$router.push({ path: `/purchase/order/${data.order_id}` })
          })
          .catch(err => {
            if (err.response?.data?.code === 422 && err.response?.data?.name) {
              this.outOfStock.push(err.response.data.stock_detail)
              this.$notification.error({
                message: err.response?.data?.name || this.$t('purchase.catalogue.outOfStock'),
              })
              const element = document.getElementById('product')
              element.scrollIntoView({
                behavior: 'smooth',
              })
            } else if (err.response.status === 422) {
              this.$notification.error({
                message: err.response?.data?.message || err.response.message,
              })
              const element = document.getElementById('po-number')
              element.scrollIntoView({
                behavior: 'smooth',
              })
            } else {
              this.$notification.error({
                message: err.response?.data?.message || err.response.message,
              })
            }
          })
      })
    },
  },
  mounted: function () {
    this.$store.dispatch('purchase/GETCOURIER')
    this.$store.dispatch('purchase/GETPAYMENTMETHOD')
    // this.$store.dispatch('purchase/GETUSERADDRESS')
    this.$store.dispatch('data/GETREGIONCOUNTRY', this.app.id)
  },
}
</script>
