var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{attrs:{"form":_vm.form}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('a-form-item',{attrs:{"label":"Diterima pada Tanggal:"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['date', {
            rules: [{
              required: true
            }]
          }]),expression:"['date', {\n            rules: [{\n              required: true\n            }]\n          }]"}],staticStyle:{"width":"75%"}})],1),_c('a-form-item',{attrs:{"label":"Diterima oleh:"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['received', {
            rules: [{
              required: true
            }]
          }]),expression:"['received', {\n            rules: [{\n              required: true\n            }]\n          }]"}],staticStyle:{"width":"75%"}})],1)],1),_c('div',{staticClass:"col-12 col-md-6 text-right"},[_c('a-button',{staticClass:"text-center",attrs:{"type":"primary","htmlType":"submit"}},[_c('strong',[_vm._v(" Konfirmasi Penerimaan ")])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }