/* eslint-disable */
import { Card } from 'card-validator'

/**
 * 
 * @param {Card} card 
 * 
 * @returns {string}
 */
export const generatePattern = card => {
  let pattern = '';

  if(!card) {
    return defaultPattern;
  }

  const max = Math.max(...card.lengths);
  for(let i = 0; i < max; i++) {
    if(card.gaps.includes(i)) {
      pattern += '';
    }

    pattern += '9';
  }

  return pattern;
}

export const months = () => [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12'
];

export const years = (yearNumber = 8) => {
  const y = [];
  const current = new Date().getFullYear();

  for(let i = current; i < (current + yearNumber); i++) {
    y.push(i);
  }

  return y;
}

export const images = {
  mastercard: 'https://ik.imagekit.io/powerbiz/utils/mastercard.svg',
  visa: 'https://ik.imagekit.io/powerbiz/utils/visa.svg',
  'american-express': 'https://ik.imagekit.io/powerbiz/utils/amex.svg',
  jcb: 'https://ik.imagekit.io/powerbiz/utils/jcb.svg',
}

export const generateMidtransToken = ({ card_number, card_exp_month, card_exp_year, card_cvv }) => {
  return new Promise((resolve, reject) => {
    if(typeof MidtransNew3ds === 'undefined') {
      reject('MidtransNew3ds JS library not working well or not included on the app');
    }

    MidtransNew3ds.getCardToken({
      card_number,
      card_exp_month,
      card_exp_year,
      card_cvv
    }, {
      onSuccess: response => resolve(response),
      onFailure: response => reject({ response })
    })
  })
}
