<template>
  <div>
    <div v-if="tabKey === 'Pesanan'">
      <!-- <a-button :disabled="!data[0].product" @click.prevent="addNewProduct">Tambah Produk<a-icon type="plus" /></a-button> -->
    </div>
    <div class="mt-3">
      <a-table
        :columns="columns"
        :data-source="data"
        :row-key="data => data.index"
        :pagination="false"
        v-if="!settings.isMobileView"
      >
        <template #product="text, record">
          <div v-if="!record.product && !record.isButtonAddNew">
            <a-select
              show-search
              style="width: 100%"
              @change="selectProduct"
              placeholder="Select Product"
            >
              <div slot="dropdownRender" slot-scope="menu">
                <v-nodes :vnodes="menu" />
                <a-divider style="margin: 4px 0;" />
                <div
                  style="padding: 4px 8px; cursor: pointer;"
                  @mousedown="e => e.preventDefault()"
                  @click="addItem"
                >
                  <a-icon type="plus" /> Add item
                </div>
              </div>
              <a-select-option v-for="(product, index) in productList" :key="index" :value="product.title">
                {{ product.title }}
              </a-select-option>
            </a-select>
          </div>
          <div v-if="!record.product && record.isButtonAddNew">
            <a-button @click.prevent="addNewProduct"> {{ $t('purchase.addProduct') }} <a-icon type="plus" /></a-button>
          </div>
          <div v-else>
            {{ record.product }}
          </div>
        </template>

        <template #unit="text, record">
          <div v-if="record.product && record.selectUnit">
            <a-select
              show-search
              style="width: 75%"
              @change="(value) => selectUnit(value, record)"
              :defaultValue="initialValueUom"
            >
              <a-select-option v-for="(item, index) in record.list" :key="index">
                {{ item.uom }}
              </a-select-option>
            </a-select>
          </div>
        </template>

        <template #prices="text, record">
          <div v-if="record.product && record.unit"> {{ record.prices | currency }} </div>
        </template>

        <template #discount_price="text, record">
          <div v-if="record.product && record.unit"> {{ record.discount_price | currency }} </div>
        </template>

        <!-- <template #price_after_discount="text, record">
          <div v-if="record.product && record.unit"> {{ record.price_after_discount | currency }} </div>
        </template> -->

        <template #quantity="text, record">
          <div v-if="record.product">
            <a-input-number
              :step="1"
              :min="0"
              :defaultValue="1"
              @change="(value) => (
                data.forEach((item) => {
                  if (item.product === record.product && item.index === record.index) {
                    item.quantity = value
                    item.total_price = value * item.price_after_discount
                    item.isOutOfStock = false
                    return item
                  }
                  return item
                })
              )"
            />
          </div>
          <div v-if="record.isOutOfStock" class="text-danger mt-2">
            {{ $t('purchase.catalogue.outOfStock') }}
          </div>
        </template>

        <template #total_price="text, record">
          <div v-if="record.product && record.unit">
            {{ record.total_price | currency }}
          </div>
        </template>

        <template #action="text, record">
          <div v-if="data.length && !record.isButtonAddNew" style="cursor: pointer;" @click.prevent="handleClose(record)">
            <a-icon type="delete" />
          </div>
        </template>
      </a-table>

      <div v-if="settings.isMobileView">
        <div v-for="record in data" :key="record.product">
          <div class="mb-4">
            <div class="d-flex justify-content-between">
              <div class="mb-1 text-muted"> Product: </div>
              <div v-if="data.length && !record.isButtonAddNew" style="cursor: pointer;" @click.prevent="handleClose(record)">
                <a-icon type="close" />
              </div>
            </div>
            <div v-if="!record.product && !record.isButtonAddNew">
              <a-select
                show-search
                style="width: 100%"
                @change="selectProduct"
                placeholder="Select Product"
              >
                <div slot="dropdownRender" slot-scope="menu">
                  <v-nodes :vnodes="menu" />
                  <a-divider style="margin: 4px 0;" />
                  <div
                    style="padding: 4px 8px; cursor: pointer;"
                    @mousedown="e => e.preventDefault()"
                    @click="addItem"
                  >
                    <a-icon type="plus" /> Add item
                  </div>
                </div>
                <a-select-option v-for="(product, index) in productList" :key="index" :value="product.title">
                  {{ product.title }}
                </a-select-option>
              </a-select>
            </div>
            <div v-if="!record.product && record.isButtonAddNew">
              <a-button @click.prevent="addNewProduct"> {{ $t('purchase.addProduct') }} <a-icon type="plus" /></a-button>
            </div>
            <div v-else class="text-bold">
              {{ record.product }}
            </div>
          </div>

          <div class="mb-4">
            <div class="mb-1 text-muted"> Qty: </div>
            <div v-if="record.product">
              <a-input-number
                :step="1"
                :min="0"
                :defaultValue="1"
                @change="(value) => (
                  data.forEach((item) => {
                    if (item.product === record.product && item.index === record.index) {
                      item.quantity = value
                      item.total_price = value * item.price_after_discount
                      item.isOutOfStock = false
                      return item
                    }
                    return item
                  })
                )"
              />
            </div>
            <div v-if="record.isOutOfStock" class="text-danger mt-2">
              {{ $t('purchase.catalogue.outOfStock') }}
            </div>
          </div>

          <div class="mb-4">
            <div class="mb-1 text-muted"> Unit: </div>
            <a-select
              show-search
              style="width: 75%"
              @change="(value) => selectUnit(value, record)"
              :defaultValue="initialValueUom"
              v-if="record.product && record.selectUnit"
            >
              <a-select-option v-for="(item, index) in record.list" :key="index">
                {{ item.uom }}
              </a-select-option>
            </a-select>
          </div>

          <div class="mb-4">
            <div class="mb-1 text-muted"> {{ $t('purchase.price') }}: </div>
            <div v-if="record.product && record.unit"> {{ record.prices | currency }} </div>
          </div>

          <div class="mb-4">
            <div class="mb-1 text-muted"> {{ $t('purchase.discount') }}: </div>
            <div v-if="record.product && record.unit"> {{ record.discount_price | currency }} </div>
          </div>

          <div class="mb-4">
            <div class="mb-1 text-muted"> Total </div>
            <div v-if="record.product && record.unit">
              {{ record.total_price | currency }}
            </div>
          </div>
          <a-tabs />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const newProduct = {
  id: null,
  catalog_id: null,
  product_id: null,
  product: '',
  unit: null,
  unit_id: null,
  selectUnit: false,
  prices: null,
  quantity: null,
  total_price: null,
  weight: null,
  discount_price: null,
  price_after_discount: null,
  isOutOfStock: false,
  list: [],
}

export default {
  name: 'purchase.update.listproduct',
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  props: ['tabKey', 'outOfStock'],
  data: function () {
    return {
      columns: [
        {
          title: 'Product',
          dataIndex: 'product',
          key: 'product',
          scopedSlots: { customRender: 'product' },
          class: 'align-middle',
          width: 350,
        },
        {
          title: 'Qty',
          dataIndex: 'quantity',
          key: 'quantity',
          scopedSlots: { customRender: 'quantity' },
          class: 'align-middle text-center',
          // width: 100,
        },
        {
          title: 'Unit',
          dataIndex: 'unit',
          key: 'unit',
          scopedSlots: { customRender: 'unit' },
          class: 'align-middle text-center',
          // width: 300,
        },
        {
          title: this.$t('purchase.price'),
          dataIndex: 'prices',
          key: 'prices',
          scopedSlots: { customRender: 'prices' },
          class: 'align-middle text-center',
          // width: 250,
        },
        {
          title: this.$t('purchase.discount'),
          dataIndex: 'discount_price',
          key: 'discount_price',
          scopedSlots: { customRender: 'discount_price' },
          class: 'align-middle text-center',
          // width: 250,
        },
        {
          title: 'Total',
          dataIndex: 'total_price',
          key: 'total_price',
          scopedSlots: { customRender: 'total_price' },
          class: 'align-middle text-center',
          // width: 250,
        },
        {
          title: null,
          dataIndex: 'action',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          class: 'align-middle text-center',
          // width: 50,
        },
      ],
      data: [],
      page: 1,
      limit: 25,
      initialValueUom: null,
    }
  },
  computed: {
    ...mapState(['purchase', 'product', 'settings', 'user']),
    loading () {
      return this.purchase.loading
    },
    productList () {
      // const filterProduct = this.purchase.productListPurhcase.filter((item, index) => {
      //   if (!this.data.find((value) => value.product === item.title)) {
      //     return item
      //   }
      // })
      // return filterProduct
      return this.purchase.productListPurhcase
    },
    channel_id () {
      return this.user.channel_id
    },
  },
  methods: {
    addNewProduct () {
      this.data.forEach(item => {
        if (!item.product && item.isButtonAddNew) {
          item.isButtonAddNew = false
          return item
        }
        return item
      })
    },
    selectProduct (value) {
      const findProduct = this.purchase.productListPurhcase.find((item, index) => item.title === value)
      let record = null

      this.data.forEach(item => {
        if (!item.product) {
          item.id = findProduct.id
          item.isButtonAddNew = false
          item.product = findProduct.title
          item.list = findProduct.price_list
          item.catalog_id = findProduct.catalog_id
          item.product_id = findProduct.product_id
          item.quantity = 1
          item.selectUnit = true
          record = item
          return item
        }
        return item
      })

      // console.log(record, 'ini record')
      this.initialValueUnit(record)

      this.data.push({ ...newProduct, isButtonAddNew: true, index: this.data[this.data.length - 1].index + 1 })
    },
    addItem (value) {
      this.page += 1
      this.$store.dispatch('product/GETPRODUCTLIST', {
        page: this.page,
        limit: this.limit,
      })
    },
    handleClose (record) {
      if (!record.product) {
        this.data.forEach(item => {
          if (!item.product === !record.product && item.index === record.index) {
            item.isButtonAddNew = true
            return item
          }
          return item
        })
      } else if (record.product && this.data.length === 2) {
        const filteredData = this.data.filter((item) => item.catalog_id !== record.catalog_id && item.index !== record.index)
        this.data = filteredData
      } else if (record.product && this.data.length > 2) {
        const filteredData = this.data.filter((item) => (item.catalog_id !== record.catalog_id || item.product !== record.product) || (item.catalog_id === record.catalog_id && item.product === record.product && item.index !== record.index))
        this.data = filteredData
      }
    },
    selectUnit (value, record) {
      const data = record.list[value]
      this.data.forEach(item => {
        if (item.catalog_id === record.catalog_id && item.product_id === record.product_id && item.index === record.index) {
          const discount = this.countDiscount(data.price_selling, data.discount_type, data.discount_value)
          const price_after_discount = data.price_selling - this.countDiscount(data.price_selling, data.discount_type, data.discount_value)

          item.prices = data.price_selling
          item.unit = data.uom
          item.unit_id = data.uom_id
          item.weight = data.unit_weight
          item.discount_price = discount
          item.price_after_discount = price_after_discount
          item.total_price = item.quantity ? item.quantity * price_after_discount : 1 * price_after_discount
          return item
        }
        return item
      })
    },
    countDiscount (price_selling, discount_type, discount_value) {
      switch (discount_type) {
        case 1: {
          const discount_price = price_selling * discount_value / 100
          return discount_price
        }

        default:
          return 0
      }
    },
    initialValueUnit (record) {
      let price = 0
      let selected_unit = null
      let index_selected = null
      record.list.forEach((item, index) => {
        if (item.price_selling > price) {
          price = item.price_selling
          selected_unit = item
          index_selected = index
        }
      })

      this.initialValueUom = selected_unit.uom

      this.selectUnit(index_selected, record)

      // return { selected_unit, index_selected }
    },
  },
  created () {
    this.data = [
      {
        index: 0,
        id: null,
        isButtonAddNew: false,
        catalog_id: null,
        product_id: null,
        product: '',
        unit: null,
        unit_id: null,
        selectUnit: false,
        prices: null,
        quantity: null,
        total_price: null,
        weight: null,
        discount_price: null,
        price_after_discount: null,
        isOutOfStock: false,
        list: [],
      },
    ]
    // this.data = newProduct
    this.$store.dispatch('purchase/GETPRODUCTLISTPURCHASE', {
      channel_id: this.channel_id,
    })
    // this.$emit('updateProduct', this.data)
  },
  watch: {
    data: {
      deep: true,
      handler: function () {
        // console.log(this.data, 'di watch')
        const items = this.data.map((item, index) => {
          if (item.unit && item.weight && item.quantity) {
            const findPrices = item.list.find(value => value.uom === item.unit)
            const findProduct = this.purchase.productListPurhcase.find(value => value.catalog_id === item.catalog_id)
            return {
              id: item.id,
              product_id: item.product_id,
              catalog_id: item.catalog_id,
              price_id: findPrices.price_id,
              levelprice_id: findPrices.levelprice_id,
              title: findProduct.title,
              image_path: findProduct.image_path ? findProduct.image_path : '',
              image_name: findProduct.image_name ? findProduct.image_name : '',
              showimg_url: findProduct.showimg_url,
              selling_price: findPrices.price_selling,
              currency: 'IDR', // hardcode
              uom: item.unit_id,
              created_at: '', // hardcode tidak ada di data
              qty: item.quantity,
              brand_sku: '', // hardcode tidak ada di data
              weight: item.weight,
              discount_price: item.discount_price,
              price_after_discount: item.price_after_discount,
              discount_type: findPrices.discount_type,
              discount_value: findPrices.discount_value,
            }
          }
        })
        this.$emit('updateProduct', items)
      },
    },
    outOfStock: {
      deep: true,
      handler: function (newVal) {
        // console.log(val, 'in out of stock')
        this.data.forEach(item => {
          const findCatalog = newVal.find(value => value.catalog_id === item.id)
          if (findCatalog) {
            item.isOutOfStock = true
          }
          return item
        })
      },
    },
  },
}
</script>
