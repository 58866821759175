<template>
  <div>
    <div style="font-size: 20px"> {{ $t('purchase.payment.method') }} </div>
    <div class="paymentList mt-3" v-if="paymentList && sortedPaymentListKey && payment.length">
      <div v-for="method in sortedPaymentListKey" :key="method" class="paymentGroup">
        <h6> {{ method }} </h6>
        <div class="paymentGroupItems row row-cols-sm-2 row-cols-1 gutter-2">
          <div v-for="item in paymentList[method]" :key="item.id" class="mb-2">
            <label :class="{ 'paymentItemActive': item.id === selectedPaymentMethod.id }" class="paymentItem">
              <input
                type="radio"
                :value="item.id"
                @click.prevent="setSelectedPaymentMethode(item)"
              />
              <div v-if="item.meta && !!item.meta.image" class="logo">
                <img
                  :src="item.meta.image"
                  :alt="item.methode_title"
                />
              </div>
              <div v-if="item.meta">
                {{ item.meta.description }}
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div>
      <CreditCardForm
        v-if="selectedPaymentMethod && selectedPaymentMethod.meta && selectedPaymentMethod.meta.name === 'cc'"
        :form="form"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PaymentUtils from '@/utils/payment'
import CreditCardForm from '@/components/Purchase/update/PaymentMethod/CreditCard/form'

export default {
  name: 'purchase.update.payment.detail',
  components: { CreditCardForm },
  data: function () {
    return {
      paymentList: null,
      sortedPaymentListKey: null,
      selectedPaymentMethod: {},
    }
  },
  props: ['payment', 'form'],
  methods: {
    setSelectedPaymentMethode (item) {
      this.selectedPaymentMethod = (this.payment || []).find((value) => item.id === value.id)
      this.$emit('updateSelectedPayment', this.selectedPaymentMethod)
    }
  },
  watch: {
    payment: {
      deep: true,
      handler: function (val) {
        if (val.length) {
          // console.log(val)
          const hideCCandEwallet = val.filter(item => item.group_name !== 'Credit Card / Debit' && item.group_name !== 'E-Wallet')
          // console.log(hideCCandEwallet, 'filtered')
          const paymentList = !val.length ? {} : hideCCandEwallet.reduce((value, item) => {
            const [gateway, ...key] = item.methode_name.split("_");
            item.meta = PaymentUtils.get(key.join("_"))
            if(item.is_desktop) {
              (value[item.group_name] = value[item.group_name] || []).push(item);
            }
  
            return value;
          }, {});
  
          const sortedPaymentListKey = Object.keys(paymentList).sort((a, b) => {
            const key1 = paymentList[a][0];
            const key2 = paymentList[b][0];
            return key1.group_sort - key2.group_sort;
          })
          // console.log(paymentList)
          this.paymentList = paymentList
          this.sortedPaymentListKey = sortedPaymentListKey 
        }
      },
    },
  }
}
</script>

<style lang="sass">
.paymentList
  display: relative

.paymentGroup
  margin-bottom: 1rem
  
  h5
    font-size: .8rem

.paymentGroupItems
  display: flex
  flex-wrap: wrap

.paymentItem
  display: flex
  align-items: center
  margin-bottom: .5rem
  padding: .75rem 1.5rem .75rem .75rem
  border: 1px solid #eaeaea
  cursor: pointer
  position: relative
  user-select: none
  margin-right: .3rem

  input[type="radio"]
    display: none

  &:after
    content: ' '
    width: 20px
    height: 20px
    border-radius: 10rem
    right: 1rem
    top: 50%
    transform: translateY(-50%)
    position: absolute
    border: 1px solid #d1d1d1

.paymentItemActive
  // border: 1px solid #4B7CF3
  outline: 2px solid #4B7CF3

  &:after
    border-color: #fff
    border: 6px solid #4B7CF3

.logo
  width: 50px
  height: 25px
  margin-right: .75rem

  img
    max-width: 100%
    max-height: 20px

.paymentFeedback
  display: block !important

.loaderWrapper
  width: 100%
  height: 100%
  position: fixed
  left: 0
  top: 0
  right: 0
  bottom: 0
  display: flex
  justify-content: center
  align-items: center
  background: #ffffff
  z-index: 100

.loader3
  position: relative
  width: 150px
  height: 20px
  margin: auto 


  &:after
    content: "PROCESSING..."
    color: var(--primary)
    font-size: 16px
    position: absolute
    width: 100%
    height: 30px
    display: flex
    align-items: center
    justify-content: center
    line-height: 20px
    left: 0
    top: 0
    background-color: #ffffff
    z-index: 1

  &:before
    content: ""
    position: absolute
    background-color: var(--primary)
    top: -5px
    left: 0px
    height: 40px
    width: 0px
    z-index: 0
    opacity: 1
    -webkit-transform-origin: 100% 0%
    transform-origin: 100% 0%
    animation: loader3 3s ease-in-out infinite

@keyframes loader3
  0%
    width: 0px
  70%
    width: 100% 
    opacity: 1
  90%
    opacity: 0 
    width: 100%
  100%
    opacity: 0
    width: 0px
</style>
