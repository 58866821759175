/* eslint-disable */
module.exports = {
  name: "bni_va",
  description: "BNI Virtual Account",
  image: "https://ik.imagekit.io/powerbiz/utils/BNI_logo.svg",
  instructions: {
    "ATM BNI": [
      `Masukkan kartu ATM Anda`,
      `Pilih bahasa`,
      `Masukkan PIN ATM Anda`,
      `Pilih <strong>Menu Lainnya</strong> &gt; <strong>Transfer </strong>&gt;<strong> Rekening Tabungan </strong>&gt;<strong> Ke Rekening BNI</strong>`,
      `Masukkan nomor rekening tujuan dengan 16 digit Nomor Virtual Account`,
      `Masukkan nominal transfer sesuai tagihan atau kewajiban Anda. Nominal yang berbeda tidak dapat diproses`,
      `Konfirmasi, apabila telah sesuai, lanjutkan transaksi`,
      `Transaksi telah selesai`
    ],
    "iBank Personal": [
      `Ketik alamat <strong>https://ibank.bni.co.id</strong> kemudian klik "Enter"`,
      `Masukkan User ID dan Password`,
      `Klik menu <strong>TRANSFER</strong> kemudian pilih <strong>TAMBAH REKENING FAVORIT</strong>. Jika menggunakan desktop untuk menambah rekening, pada menu <strong>Transaksi</strong> lalu pilih <strong>Info &amp; Administrasi Transfer</strong> kemudian <strong>Atur Rekening Tujuan</strong> lalu <strong>Tambah Rekening Tujuan</strong>`,
      `Masukkan <strong>Nomor Virtual Account</strong> sebagai nomor rekening tujuan`,
      `Masukkan <strong>Kode Otentikasi Token</strong>. Nomor rekening tujuan berhasil ditambahkan`,
      `Kembali ke menu <strong>TRANSFER</strong>. Pilih <strong>TRANSFER ANTAR REKENING BNI</strong>, kemudian pilih rekening tujuan`,
      `Pilih Rekening Debit dan ketik nominal transfer sesuai tagihan atau kewajiban Anda. Nominal yang berbeda tidak dapat diproses`,
      `Lalu masukkan kode otentikasi token`,
      `Transfer Anda telah berhasil`
    ],
    "Mobile Banking": [
      `Akses <strong>BNI Mobile Banking</strong> dari handphone kemudian masukkan <strong>user ID</strong> dan <strong>password</strong>`,
      `Pilih menu <strong>Transfer</strong>`,
      `Pilih <strong>Antar Rekening BNI</strong> kemudian <strong>Input Rekening Baru</strong>`,
      `Masukkan nomor Rekening Debit dan Virtual Account Tujuan`,
      `Masukkan nominal transfer sesuai tagihan atau kewajiban Anda. Nominal yang berbeda tidak dapat diproses`,
      `Konfirmasi transaksi dan masukkan Password Transaksi`,
      `Transfer Anda telah berhasil`
    ],
    "SMS Banking": [
      `Buka aplikasi <strong>SMS Banking BNI</strong>`,
      `Pilih menu Transfer`,
      `Pilih menu <strong>Trf rekening BNI</strong>`,
      `Masukkan nomor rekening tujuan dengan 16 digit Nomor Virtual Account`,
      `Masukkan nominal transfer sesuai tagihan atau kewajiban Anda. Nominal yang berbeda tidak dapat diproses`,
      `Pilih "<strong>Proses</strong>" kemudian "<strong>Setuju</strong>"`,
      `Reply SMS dengan ketik PIN sesuai perintah`,
      `Transaksi berhasil`,
      `<p>Atau dapat juga langsung mengetik SMS dengan format:<br><strong>TRF[SPASI]NomorVA[SPASI]NOMINAL</strong><br>dan kemudian kirim ke <strong>3346</strong><br>Contoh : TRF 8035085694965855 100000`
    ],
    "ATM Bersama": [
      `Masukkan kartu ke mesin <strong>ATM bersama</strong>`,
      `Pilih <strong>Transaksi Lainnya,</strong> pilih menu <strong>Transfer</strong>, lalu pilih <strong>Transfer ke Bank Lain</strong>`,
      `Masukkan <strong>kode</strong> <strong>bank BNI</strong> (<strong>009</strong>) dan 16 Digit Nomor Virtual Account`,
      `Masukkan nominal transfer sesuai tagihan atau kewajiban Anda. Nominal yang berbeda tidak dapat diproses`,
      `Konfirmasi rincian Anda akan tampil di layar, cek dan tekan \'<strong>Ya</strong>\' untuk melanjutkan`,
      `Transaksi berhasil`
    ],
    "Transfer dari Bank Lain": [
      `Pilih menu <strong>Transfer antar bank</strong> atau <strong>Transfer online antarbank</strong>`,
      `Masukkan <strong>kode bank BNI</strong> (<strong>009</strong>) atau pilih bank yang dituju yaitu <strong>BNI</strong>`,
      `Masukan 16 Digit Nomor VA pada kolom rekening tujuan`,
      `Masukkan jumlah pembayaran`,
      `Konfirmasi rincian Anda akan tampil di layar, cek dan apabila sudah sesuai silahkan lanjutkan transaksi sampai dengan selesai<br>`
    ]
  }
};
