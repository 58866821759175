/* eslint-disable */
module.exports = {
  name: "permata_va",
  description: "Permata Virtual Account",
  image: "https://ik.imagekit.io/powerbiz/utils/permatava.png",
  instructions: {
    "ATM Permata": [
      `Masukkan kartu ATM Bank Permata &amp; PIN pada ATM`,
      `Pilih <strong>Menu Transfer</strong>, lalu <strong>Transfer antar Rekening Permata Bank</strong>`,
      `Masukkan nomor <strong>Virtual Account</strong>`,
      `Pilih <strong>Rekening</strong> yang akan di <strong>Debit</strong>`,
      `<strong>Konfirmasi</strong> dan selesaikan pembayaran`
    ],
    "Internet Banking Permata": [
      `Login ke <strong>iBanking Permata,</strong> pilih <strong>Transfer</strong>`,
      `Pilih <strong>Antar Rekening Permata bank</strong>`,
      `Masukkan nomor <strong>Rekening Asal &amp; Virtual Account</strong>`,
      `Masukkan <strong>kode SMS / Token</strong> &amp; transaksi selesai`
    ],
    "Mobile Banking Permata": [
      `Buka <strong>Aplikasi Permata</strong>, pilih menu <strong>Transfer</strong>`,
      `Pilih menu <strong>Antar Rekening Permata Bank</strong>`,
      `Masukkan nomor <strong>Rekening Asal &amp; Virtual Account</strong>`,
      `Masukkan <strong>kode SMS / Token</strong> &amp; transaksi selesai`
    ],
    "ATM Bersama": [
      `Masukkan <strong>kartu ATM Bank Permata</strong> ke mesin <strong>ATM Bersama (Prima / Alto)</strong>`,
      `Pilih <strong>Transfer</strong>, lalu <strong>Transfer antar Rekening Permata Bank</strong>`,
      `Masukkan <strong>kode</strong> <strong>Bank Permata</strong> (<strong>013</strong>) dan nomor Virtual Account`,
      `<strong>Konfirmasi dan selesaikan</strong> pembayaran`
    ],
    "Transfer dari Bank Lain": [
      `Pilih menu <strong>Transfer antar bank</strong> atau <strong>Transfer online antarbank</strong>`,
      `Masukkan <strong>kode bank Permata</strong> (<strong>013</strong>) atau pilih bank yang dituju yaitu Bank Permata`,
      `Masukan Nomor VA pada kolom rekening tujuan`,
      `Masukkan jumlah pembayaran`,
      `Konfirmasi rincian Anda akan tampil di layar, cek dan apabila sudah sesuai silahkan lanjutkan transaksi sampai dengan selesai`
    ]
  }
};
