<template>
  <div class="col-12 col-sm-12 col-md-6">
    <div class="mb-3 d-flex justify-content-between justify-content-md-between">
      <div class="d-flex flex-column">
        <div> Total Pesanan: </div>
        <div class="text-muted"> (Nilai Barang) </div>
      </div>
      <div class="col-6 col-md-4 d-flex justify-content-between pr-0">
        <div class="text-left"> Rp </div>
        <div class="text-right"> {{ billingData.subTotal | currency_2 }}  </div>
      </div>
    </div>
    <!-- <div class="mb-3 d-flex justify-content-between justify-content-md-between">
      <div class="d-flex flex-column">
        <div> Ongkos Kirim: </div>
        <div class="text-muted" v-if="rate && selectedCourier"> {{ selectedCourier.courier_name }} - {{ selectedCourier.courier_services_name }} </div>
      </div>
      <div class="col-6 col-md-4 pr-0 d-flex justify-content-between">
        <div class="text-left"> Rp </div>
        <div class="text-right"> {{ shippingCost() | currency_2  }} </div>
      </div>
    </div> -->
    <!-- <div class="mb-3 d-flex justify-content-between justify-content-md-between" v-if="selectedCourier && selectedCourier.insurance">
      <div> Insurance: </div>
      <div class="col-6 col-md-4 pr-0 d-flex justify-content-between">
        <div class="text-left"> Rp </div>
        <div class="text-right"> {{ insurance() | currency_2  }} </div>
      </div>
    </div> -->
    <!-- <div class="mb-3 d-flex justify-content-between justify-content-md-between">
      <div> Pajak: </div>
      <div class="col-6 col-md-4 pr-0 d-flex justify-content-between">
        <div class="text-left"> Rp </div>
        <div class="text-right"> {{ tax() | currency_2 }} </div>
      </div>
    </div> -->
    <div class="mb-3 d-flex justify-content-between justify-content-md-between">
      <div> Total Diskon </div>
      <div class="col-6 col-md-4 pr-0 d-flex justify-content-between">
        <div class="text-left"> - Rp </div>
        <div class="text-right"> {{ billingData.discount | currency_2 }} </div>
      </div>
    </div>

    <div class="mb-3 d-flex justify-content-between justify-content-md-between">
      <div> Voucher <span v-if="billingData.voucher.code"> ({{ billingData.voucher.code }}) </span> </div>
      <div class="col-6 col-md-4 pr-0 d-flex justify-content-between">
        <div class="text-left"> - Rp </div>
        <div class="text-right"> {{ billingData.voucher_value | currency_2 }} </div>
      </div>
    </div>

    <a-tabs />
    <div class="mb-3 d-flex justify-content-between justify-content-md-between">
      <div> Total Tagihan: </div>
      <div class="col-6 col-md-4 pr-0 d-flex justify-content-between">
        <div class="text-left"> Rp </div>
        <!-- <div class="text-right"> {{ syncBillingTotal() | currency_2 }} </div> -->
        <div class="text-right"> {{ billingTotal | currency_2 }} </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'purchase.update.billing.total',
  data: function () {
    return {
      rate: null,
      billingData: {
        subTotal: 0,
        shippingCost: 0,
        insurance_price: 0,
        discount: 0,
        tax: 0,
        voucher_value: 0,
        voucher: {
          code: null,
          type: null,
        },
        total: 0,
      },
    }
  },
  props: {
    products: {
      type: Array,
      default: Array,
    },
    selectedCourier: {
      type: Object,
      default: null,
    },
    voucher: {
      type: Object,
      default: null,
    },
  },
  watch: {
    products: {
      deep: true,
      handler: function (val) {
        if (this.isProductsCompleted()) {
          const products = this.products.filter(item => {
            if (item) {
              return item
            }
          })

          const getSubTotal = products.reduce((acc, item) => acc + item.selling_price * item.qty, 0)
          const getDiscount = products.reduce((acc, item) => acc + item.discount_price * item.qty, 0)
          this.billingData.subTotal = getSubTotal
          this.billingData.discount = getDiscount
          this.billingData.total = this.billingData.subTotal - this.billingData.discount
        } else {
          this.billingData.subTotal = 0
          this.billingData.discount = 0
          this.billingData.total = this.billingData.subTotal - this.billingData.discount
        }

        this.syncBillingTotal()
      },
    },
    selectedCourier: {
      deep: true,
      handler: function (val) {
        this.getRate()
      },
    },
    voucher: {
      deep: true,
      handler: function () {
        if (!this.voucher) {
          this.billingData.voucher_value = 0
          this.billingData.voucher.code = null
          this.billingData.voucher.type = null
        } else {
          const { transaction_data: data } = this.voucher
          this.billingData.voucher_value = data.value
          this.billingData.voucher.code = this.voucher.code
          this.billingData.voucher.type = this.voucher.type
        }

        this.syncBillingTotal()
      },
    },
  },
  computed: {
    billingTotal () {
      if (this.voucher && !this.isProductsCompleted()) {
        return 0
      } else {
        return this.billingData.total
      }
    },
  },
  methods: {
    isProductsCompleted () {
      const products = this.products.filter(item => {
        if (item) {
          return item
        }
      })

      let isProductsCompleted = true
      if (products.length) {
        products.forEach(item => {
          if (!item || !item.uom || (!item.catalog_id && !item.product_id && !item.unit && !item.quantity)) {
            isProductsCompleted = false
          }
        })
      } else {
        isProductsCompleted = false
      }
      return isProductsCompleted
    },
    getRate () {
      if (this.selectedCourier) {
        this.$store.dispatch('purchase/GETRATE', {
          total: this.totalWeight() ? this.totalWeight() : 0,
          courier: this.selectedCourier.courier_name,
        })
          .then(({ data }) => {
            this.rate = data
            this.shippingCost()
          })
      }
    },
    totalWeight () {
      if (this.products.length && this.isProductsCompleted()) {
        const products = this.products.filter(item => {
          if (item) {
            return item
          }
        })
        const weight = products.reduce(
          (acc, current) => acc + current.weight * current.qty,
          0,
        )
        return weight
      }
    },
    shippingCost () {
      if (this.rate && this.selectedCourier) {
        const findCourier = this.rate.courier.find(item => item.service.code === this.selectedCourier.courier_services_code)
        this.$emit('updateRate', {
          warehouse_id: this.rate.warehouse_id,
          origin_id: this.rate.origin_id || '',
          ...findCourier,
        })
        this.billingData.shippingCost = findCourier ? findCourier.price : 0
        this.$emit('updateBillingData', this.billingData)
        return findCourier ? findCourier.price : 0
      } else {
        return this.billingData.shippingCost
      }
    },
    tax () {
      return 0
    },
    syncBillingTotal () {
      if (this.voucher && this.isProductsCompleted()) {
        const { transaction_data: data } = this.voucher
        let discount = 0
        if (Number(this.voucher.type_id) === 1) {
          discount = data.type === 1 ? Number((this.billingData.total * data.value) / 100) : Number(data.value)
          if (discount > data.max_discount) {
            discount = data.max_discount
          }

          if (discount > this.billingData.total) {
            discount = this.billingData.total
          }
        }

        this.billingData.voucher_value = discount
        this.billingData.total = this.billingData.subTotal - this.billingData.discount - this.billingData.voucher_value
      } else if (this.voucher && !this.isProductsCompleted()) {
        const { transaction_data: data } = this.voucher

        this.billingData.voucher_value = data.type === 1 ? `${data.value}%` : Number(data.value)
        this.billingData.total = 0
      } else {
        this.billingData.total = this.billingData.subTotal - this.billingData.discount - this.billingData.voucher_value
      }

      this.$emit('updateBillingData', this.billingData)
    },
  },
}
</script>
