<template>
  <div class="col-6 card-form">
    <a-form-item
      label="Card Number"
    >
      <a-input
        v-decorator="['credit_card.cc_no', {
          rules: [{
            required: true
          }]
        }]"
        @change="() => isUpdateCardNumber = true"
        v-mask="'#### #### #### ####'"
      />
      <ul class="card-form__images">
        <li
          v-for="(cc, index) in Object.keys(images)"
          :key="index"
          :style="card && cc === card.type && 'opacity: 1'"
        >
          <img
            :src="images[cc]"
            :alt="cc"
            :style="card && cc === card.type && 'filter: none'"
          />
        </li>
      </ul>
    </a-form-item>
    <a-form-item
      label="Card Holder"
    >
      <a-input
        v-decorator="['credit_card.holder_name', {
          rules: [{
            required: true
          }]
        }]"
      />
    </a-form-item>
    <div class="row d-flex">
      <div class="col-12 mb-2"> Expiries </div>
      <div class="col-5">
        <a-form-item>
          <a-select
            v-decorator="['credit_card.exp_month', {
              rules: [{
                required: true,
              }],
            }]"
            placeholder="Month"
          >
            <a-select-option v-for="(month, index) in months()" :key="index" :value="month">
              {{ month }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>
      <div class="col-5">
        <a-form-item label="">
          <a-select
            v-decorator="['credit_card.exp_year', {
              rules: [{
                required: true,
              }],
            }]"
            placeholder="Year"
          >
            <a-select-option v-for="(year, index) in years()" :key="index" :value="year">
              {{ year }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>
      <div class="col-2">
        <a-form-item>
          <a-input
            type="password"
            v-decorator="['credit_card.cvv', {
              rules: [{
                required: true,
              }],
            }]"
            :maxLength="4"
            placeholder="CVV"
          />
        </a-form-item>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import CardValidator from 'card-validator'
import { images, months, years } from '@/components/Purchase/update/PaymentMethod/CreditCard/uitls'

export default {
  props: ['form'],
  directives: { mask },
  data: function () {
    return {
      images,
      months,
      years,
      isUpdateCardNumber: false,
      card: null,
    }
  },
  watch: {
    isUpdateCardNumber () {
      if (this.isUpdateCardNumber) {
        const val = this.form.getFieldValue('credit_card.cc_no').replace(/\s+/g, '')
        const { card } = CardValidator.number(val)
        this.card = card

        this.isUpdateCardNumber = false
      }
    },
  },
}
</script>

<style lang="scss">
.card-form {
  &__images {
    list-style: none;
    display: flex;
    padding: 0;
    margin-top: .25rem;

    li {
      opacity: .6;
    }

    img {
      height: 17px;
      filter: grayscale(1);
    }

    &:not(:last-child) {
      padding-right: .5rem;
    }
  }
}
</style>
