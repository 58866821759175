<template>
  <CreatePurchase />
</template>

<script>
import CreatePurchase from '@/components/Purchase/update/index'

export default {
  name: 'purhcase.update',
  components: {
    CreatePurchase,
  },
}
</script>
