/* eslint-disable */
module.exports = {
    name: "bri_va",
    description: "BRI Virtual Account",
    image: "https://ik.imagekit.io/powerbiz/utils/briva.png",
    instructions: {
      "ATM BRI": [
        `Masukkan kartu ATM BRI &amp; PIN`,
        `Pada ATM, Pilih <strong>Transaksi Lain</strong>, kemudian pilih <strong>pembayaran</strong>.`,
        `Kemudian Pilih <strong>Lainnya</strong>, lalu pilih <strong>BRIVA</strong>.`,
        `Masukkan nomor <strong>BRIVA </strong>dan <strong>nominal pembayaran</strong>.`,
        `<strong>Konfirmasi</strong> Jumlah Pembayaran dan <strong>selesaikan</strong> pembayaran.`,
      ],
      "Internet Banking BRI": [
        `Login <strong>iBanking BRI</strong>, kemudian pilih <strong>Pembayaran Tagihan</strong>.`,
        `Pilih <strong>Pembayaran</strong>kemudian pilih <strong>BRIVA</strong>.`,
        `Masukkan <strong>nomor BRIVA</strong>dan <strong>Nominal Pembayaran</strong>.`,
        `Masukkan <strong>Password</strong>dan <strong>mTOken</strong>, klik <strong>Kirim</strong>, &amp; selesaikan pembayaran.`
      ],
      "Mobile Banking BRI": [
        `Buka aplikasi BRI Mobile, <strong>pilih Mobile Banking</strong>.`,
        `Pilih <strong>Pembayaran</strong>, kemudian pilih <strong>BRIVA</strong>.`,
        `Masukkan <strong>nomor BRIVA</strong>dan <strong>Nominal Pembayaran</strong>.`,
        `Masukkan <strong>PIN</strong>, tekan <strong>OK/Kirim</strong> dan selesaikan Pembayaran.`
      ],
      "ATM Bersama": [
        `Masukkan kartu ke mesin <strong>ATM bersama</strong>`,
        `Pilih <strong>Transaksi Lainnya,</strong> pilih menu <strong>Transfer</strong>, lalu pilih <strong>Transfer ke Bank Lain</strong>`,
        `Masukkan <strong>kode</strong> <strong>bank BRI</strong> (<strong>002</strong>) dan 16 Digit Nomor Virtual Account`,
        `Masukkan nominal transfer sesuai tagihan atau kewajiban Anda. Nominal yang berbeda tidak dapat diproses`,
        `Konfirmasi rincian Anda akan tampil di layar, cek dan tekan \'<strong>Ya</strong>\' untuk melanjutkan`,
        `Transaksi berhasil`
      ],
      "Transfer dari Bank Lain": [
        `Pilih menu <strong>Transfer antar bank</strong> atau <strong>Transfer online antarbank</strong>`,
        `Masukkan <strong>kode bank BRI</strong> (<strong>002</strong>) atau pilih bank yang dituju yaitu BRI`,
        `Masukan 16 Digit Nomor VA pada kolom rekening tujuan`,
        `Masukkan jumlah pembayaran`,
        `Konfirmasi rincian Anda akan tampil di layar, cek dan apabila sudah sesuai silahkan lanjutkan transaksi sampai dengan selesai`
      ]
    }
  };
  