<template>
  <div>
    <div class="title row" style="font-size: 20px; align-items: center">
      <div class="col-6">
        Billing Data
      </div>
      <div class="col-5 text-right" v-if="billData && (changeBillData || createNewBill)">
        <span style="font-size: 3rem; cursor: pointer" @click.prevent="() => handleRemove()">
          &times;
        </span>
      </div>
    </div>

    <div class="mt-3 row" v-if="lastBillAddress && !changeBillData && !createNewBill">
      <div class="col-12 col-md-6">
        <div class="mb-2 text-muted"> Billing Data: </div>
        <div class="mn-2 font-weight-bold">
          {{ billData.first_name + ' ' + billData.name }} <br />
          {{ billData.phone }} <br />
          {{ billData.address_line1 }} <br />
          {{ [billData.subdistrict, billData.district].join(', ') }}{{' '}}
          <br />
          {{ [
            billData.city,
            billData.province,
            billData.country,
          ].join(', ') }}{{' '}}
        </div>
        <div class="mt-3">
          <a-button @click.prevent="handleChangeBillData()"> {{ $t('purchase.billing.change') }} </a-button>
        </div>
      </div>
    </div>

    <div class="mt-3 row" v-if="!lastBillAddress && changeBillData && !createNewBill">
      <div class="col-12 col-md-6">
        <div class="mb-2"> {{ $t('purchase.billing.select') }} </div>
        <div class="mt-2">
          <a-select placeholder="Pilih Billing data" @change="selectBilling">
            <a-select-option
              v-for="(addr, index) in allBillData"
              :key="index"
              :value="addr.uuid"
            >
              {{ addr.first_name + ' ' + addr.name }} - {{ addr.phone }} -  {{ [addr.city, addr.province, addr.country].join(', ') }}
            </a-select-option>
          </a-select>
        </div>
        <div class="mt-3">
          <a-button @click.prevent="handelCreateBillData()"> {{ $t('purchase.createNew') }} </a-button>
        </div>
      </div>
    </div>

    <div class="mt-3 row" v-if="!lastBillAddress && !changeBillData">
      <div class="col-12 col-md-6 checkbox-use-shipping-address">
        <a-form-item>
          <a-checkbox
            v-decorator="['checkbox']"
            style="font-size: 16px"
            :checked="checked"
            @change="changeUseBillingSameShipping"
          >
            Gunakan alamat pengiriman
          </a-checkbox>
        </a-form-item>
      </div>
    </div>

    <div class="mt-3 row" v-if="!lastBillAddress && !changeBillData && createNewBill && !checked">
      <div class="col-12 col-md-6">
        <a-form-item :label="$t('authForm.firstName')">
          <a-input
            v-decorator="['billing_first_name', {
              rules: [{
                required: true
              }],
            }]"
            :placeholder="$t('authForm.firstName')"
          />
        </a-form-item>
      </div>
      <div class="col-12 col-md-6">
        <a-form-item :label="$t('authForm.lastName')">
          <a-input
            v-decorator="['billing_name', {
              rules: [{
                required: true
              }],
            }]"
            :placeholder="$t('authForm.lastName')"
          />
        </a-form-item>
      </div>
      <div class="col-12 col-md-6">
        <a-form-item :label="$t('authForm.phoneNumber')">
          <a-input
            v-decorator="['billing_phone', {
              rules: [{
                required: true,
                pattern: new RegExp(/[0-9\+]+/g, '')
              }],
            }]"
            :placeholder="$t('authForm.phoneNumber')"
          />
        </a-form-item>
      </div>
      <div class="col-12 col-md-6">
        <a-form-item :label="$t('authForm.emailAddress')">
          <a-input
            type="email"
            v-decorator="['billing_email', {
              initialValue: $store.getters['user/user_email']
            }]"
            :placeholder="$t('authForm.emailAddress')"
          />
        </a-form-item>
      </div>
      <div class="col-12 col-md-12">
        <a-form-item :label="$t('authForm.address')" class="col-12 col-md-6">
          <a-input
            v-decorator="['billing_address1', {
              rules: [{
                required: true
              }],
            }]"
            :placeholder="$t('authForm.address')"
          />
        </a-form-item>
      </div>
      <div class="col-12 col-md-6">
        <a-form-item :label="$t('authForm.country')">
          <a-select
            show-search
            v-decorator="['billing_country_id', {
              rules: [{
                required: true
              }],
              initialValue: data && data.regionList.length && 'Indonesia'
            }]"
            :placeholder="$t('authForm.country')"
            @change="handleSelectCountry"
          >
            <a-select-option
              v-for="(item, index) in data.regionList"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>
      <div class="col-12 col-md-6">
        <a-form-item :label="$t('authForm.city')">
          <a-select
            show-search
            v-decorator="['billing_city_id', {
              rules: [{
                required: true,
              }],
            }]"
            :placeholder="$t('authForm.placeCity')"
            @change="handleSelectCity"
          >
            <a-select-option
              v-for="(item, index) in purchase.cityListBilling"
              :value="item.label"
              :key="index"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>
      <div class="col-12 col-md-6">
        <a-form-item :label="$t('authForm.area')">
          <a-select
            show-search
            v-decorator="['billing_area_id', {
              rules: [{
                required: true,
              }],
            }]"
            :placeholder="$t('authForm.placeArea')"
            @change="handleSelectArea"
          >
            <a-select-option
              v-for="(item, index) in purchase.areaListBilling"
              :value="item.label"
              :key="index"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>
      <div class="col-12 col-md-6">
        <a-form-item :label="$t('authForm.zip')">
          <a-input
            v-decorator="['billing_codepos', {
              rules: [{
                required: true
              }],
            }]"
            :placeholder="$t('authForm.zip')"
          />
        </a-form-item>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'billing.data',
  data: function () {
    return {
      lastBillAddress: false,
      changeBillData: false,
      createNewBill: false,
      checked: null,
    }
  },
  props: [
    'form',
  ],
  computed: {
    ...mapState(['purchase', 'data']),

    billData () {
      return this.purchase.lastBillingAddress
    },

    allBillData () {
      return this.purchase.allBillingAddress
    },
  },
  methods: {
    handleRemove () {
      if (this.billData) {
        this.lastBillAddress = true
        this.changeBillData = false
        this.createNewBill = false
        this.$emit('updateIsUseBillingSameShipping', false)
      }
    },
    handleChangeBillData () {
      if (!this.purchase.allBillingAddress.length) {
        this.$store.dispatch('purchase/GETUSERBILLINGADDRESS', {
          addr_key: 'all',
        })
          .then(() => {
            this.changeBillData = true
            this.createNewBill = false
            this.lastBillAddress = false
          })
      } else {
        this.changeBillData = true
        this.createNewBill = false
        this.lastBillAddress = false
        this.$emit('updateIsUseBillingSameShipping', false)
      }
    },
    handelCreateBillData () {
      this.checked = true
      this.createNewBill = true
      this.lastBillAddress = false
      this.changeBillData = false
      this.$emit('updateIsUseBillingSameShipping', true)
      this.$store.dispatch('data/GETCITYLIST', {
        value: 228,
        type: 'city',
        location: 'billing',
      })
    },
    selectBilling (e) {
      const findDataBilling = this.allBillData.find(item => item.uuid === e)
      this.$store.commit('purchase/SET_LAST_BILLING_ADDRESS', findDataBilling)
      this.changeBillData = false
      this.createNewBill = false
      this.lastBillAddress = true
    },
    handleSelectCountry (e) {
      this.$store.dispatch('data/GETCITYLIST', {
        value: e,
        type: 'city',
        location: 'billing',
      })
    },
    handleSelectCity (e) {
      const findCity = this.purchase.cityListBilling.find(item => item.label === e)
      this.$store.dispatch('data/GETCITYLIST', {
        value: findCity.value,
        type: 'area',
        location: 'billing',
      })
    },
    handleSelectArea (e) {
      const findArea = this.purchase.areaListBilling.find(item => item.label === e)
      this.form.setFieldsValue({
        billing_codepos: findArea.postcode,
      })
    },
    changeUseBillingSameShipping (e) {
      this.$emit('updateIsUseBillingSameShipping', e.target.checked)
      this.checked = e.target.checked
    },
  },
  created () {
    this.$store.dispatch('purchase/GETUSERBILLINGADDRESS', {
      addr_key: 'last',
    })
      .then(({ data, isHaveLastBillAddress }) => {
        if (isHaveLastBillAddress) {
          this.lastBillAddress = isHaveLastBillAddress
        } else {
          // this.createNewBill = true
          this.handelCreateBillData()
        }
      })
  },
  watch: {
    createNewBill () {
      this.$emit('updateBillAddress', this.createNewBill)
    },
  },
}
</script>

<style lang="scss">
.checkbox-use-shipping-address {
  .ant-checkbox-inner {
    width: 20px !important;
    height: 20px !important;
  }
}
</style>
