var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('a-form-item',{attrs:{"label":"PO Number"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['po_number', {
          rules: [{
            required: true
          }],
          initialValue: _vm.initialValuePoNumber
        }]),expression:"['po_number', {\n          rules: [{\n            required: true\n          }],\n          initialValue: initialValuePoNumber\n        }]"}],staticStyle:{"width":"100%"}})],1)],1),_c('div',{staticClass:"col-6"},[_c('a-form-item',{attrs:{"label":"Voucher","help":_vm.isVoucherError && _vm.voucherErrorMessage}},[(_vm.voucher && !_vm.isVoucherError)?_c('div',{staticClass:"voucher-applied"},[_vm._v(" "+_vm._s(_vm.voucher.code)+" "),_c('span',{staticClass:"remove",on:{"click":function($event){$event.preventDefault();return _vm.remove.apply(null, arguments)}}},[_vm._v(" × ")])]):_vm._e(),(!_vm.voucher)?_c('a-input-search',{directives:[{name:"decorator",rawName:"v-decorator",value:(['voucher']),expression:"['voucher']"}],class:{
          'voucher-field-error': _vm.isVoucherError
        },attrs:{"placeholder":"input voucher"},on:{"search":_vm.validateVoucher,"change":_vm.handleUserInput}},[_c('a-button',{attrs:{"slot":"enterButton"},slot:"enterButton"},[_vm._v(" Terapkan ")])],1):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }