<template>
  <div class="row">
    <div class="col-6">
      <a-form-item label="PO Number">
        <a-input
          style="width: 100%"
          v-decorator="['po_number', {
            rules: [{
              required: true
            }],
            initialValue: initialValuePoNumber
          }]"
        />
      </a-form-item>
    </div>
    <div class="col-6">
      <a-form-item
        label="Voucher"
        :help="isVoucherError && voucherErrorMessage"
      >
        <div class="voucher-applied" v-if="voucher && !isVoucherError">
          {{ voucher.code }}
          <span class="remove" @click.prevent="remove">
            &times;
          </span>
        </div>
        <a-input-search
          placeholder="input voucher"
          v-decorator="['voucher']"
          @search="validateVoucher"
          @change="handleUserInput"
          :class="{
            'voucher-field-error': isVoucherError
          }"
          v-if="!voucher"
        >
          <a-button slot="enterButton">
            Terapkan
          </a-button>
        </a-input-search>
      </a-form-item>
    </div>
  </div>
</template>

<script>
import { apiClientFastify } from '@/services/axios'

export default {
  name: 'purchase.update.po.number',
  data: function () {
    return {
      initialValuePoNumber: null,
      isVoucherError: false,
      voucher: null,
      voucherErrorMessage: 'Voucher not Available',
      loading: false,
    }
  },
  props: ['form'],
  mounted () {
    this.$store.dispatch('purchase/GETPONUMBER')
      .then(({ po_number }) => {
        this.initialValuePoNumber = po_number
        this.$emit('updateInitialPONumber', po_number)
      })
  },
  methods: {
    async validateVoucher () {
      // console.log(this.form.getFieldValue('voucher'))
      const voucher_code = this.form.getFieldValue('voucher')

      if (!voucher_code) {
        this.voucher = null
        this.isVoucherError = true
        this.voucherErrorMessage = 'Please enter your voucher code'
        return
      }

      try {
        this.loading = true
        const { data: response } = await apiClientFastify({
          method: 'post',
          url: '/po/private/check-voucher',
          data: {
            voucher_code,
          },
        })
        let type = null
        switch (Number(response.data.type_id)) {
          case 1 :
            type = 'TRANSACTION_VOUCHER'
            break
          case 2 :
            type = 'PRODUCT_ITEM_VOUCHER'
            break
          case 3 :
            type = 'SHIPPING_VOUCHER'
            break
          case 4:
            type = 'BRAND_VOUCHER'
            break
        }
        this.voucher = { code: voucher_code, type, ...response.data }
        this.isVoucherError = false
      } catch (error) {
        this.voucher = null
        this.isVoucherError = true
        // console.log(error.response.data.message)
        this.voucherErrorMessage = error.response.data.message || 'Internal Server Error'
      } finally {
        this.loading = false
        this.$emit('updateVoucher', this.voucher)
      }
    },
    remove () {
      this.voucher = null
      this.isVoucherError = false
      this.$emit('updateVoucher', null)
    },
    handleUserInput () {
      this.isVoucherError = false
      this.voucher = null
    },
  },
}
</script>

<style lang="scss">
.voucher-field-error {
  border: 1px solid red !important;
}

.ant-form-explain {
  color: red !important;
}

.voucher-applied {
  border: 1px solid #9be4a0;
  padding: 0 2rem 0 1rem;
  display: flex;
  align-items: center;
  font-size: .8rem;
  color: #28a745;
  font-weight: 600;
  border-left: 5px solid #79d07f;
  border-radius: 4px;
  position: relative;

  .remove {
    cursor: pointer;
    width: 20px;
    height: 20px;
    position: absolute;
    right: .25rem;
    font-size: 1.25rem;
    top: .25rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
