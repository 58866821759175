/* eslint-disable */
module.exports = {
  name: "gopay",
  description: "GoPay",
  qris: true,
  image: "https://ik.imagekit.io/powerbiz/utils/gopay.png?tr=h-100",
  instructions: {
    QRIS: [
      'Buka aplikasi pembayaran yang mendukung QRIS pilihan Anda.',
      'Scan the QR code shown on your monitor. <div><img style="max-width: 250px; width: 100%" src="https://d2f3dnusg0rbp7.cloudfront.net/snap/assets/qr-instruction-2-76e4903a94594acce1954b8b037bb321ce1770d703406ba8f2d4290d368ee574.png" /></div>',
      'Periksa detail pembayaran Anda di aplikasi, lalu ketuk Bayar.',
      'Transaksi Anda selesai.'
    ]
  }
};
