<template>
  <div>
    <div class="title row" style="font-size: 20px; align-items: center">
      <div class="col-6">
        {{ $t('purchase.shipping.detail') }}
      </div>
      <div class="col-5 text-right" v-if="billData && (changeShippData || createNewShipp)">
        <span style="font-size: 3rem; cursor: pointer" @click.prevent="() => (
          changeShippData = false,
          createNewShipp = false,
          lastShippAddress = true
        )">
          &times;
        </span>
      </div>
    </div>

    <div class="mt-3 row" v-if="lastShippAddress && !changeShippData && !createNewShipp">
      <div class="col-12 col-md-6">
        <div class="mb-2 text-muted"> Shipping Data: </div>
        <div class="mn-2 font-weight-bold">
          {{ billData.first_name + ' ' + billData.name }} <br />
          {{ billData.phone }} <br />
          {{ billData.address_line1 }} <br />
          {{ [billData.subdistrict, billData.district].join(', ') }}{{' '}}
          <br />
          {{ [
            billData.city,
            billData.province,
            billData.country,
          ].join(', ') }}{{' '}}
        </div>
        <div class="mt-3">
          <a-button @click.prevent="handleChangeShippData()"> {{ $t('purchase.shipping.change') }} </a-button>
        </div>
      </div>
    </div>

    <div class="mt-3 row" v-if="!lastShippAddress && changeShippData && !createNewShipp">
      <div class="col-12 col-md-6">
        <div class="mb-2"> {{ $t('purchase.shipping.select') }} </div>
        <div class="mt-2">
          <a-select placeholder="Pilih Shipping data" @change="selectShipping">
            <a-select-option
              v-for="(addr, index) in allShippData"
              :key="index"
              :value="addr.uuid"
            >
              {{ addr.first_name + ' ' + addr.name }} - {{ addr.phone }} -  {{ [addr.city, addr.province, addr.country].join(', ') }}
            </a-select-option>
          </a-select>
        </div>
        <div class="mt-3">
          <a-button @click.prevent="handleCreateShippData()"> {{ $t('purchase.createNew') }} </a-button>
        </div>
      </div>
    </div>

    <div class="mt-3 row">
      <div class="col-12 col-md-6">
        <a-form-item
          :label="$t('purchase.deliveryExpectations')"
          help="Hari Sabtu, Minggu dan hari libur, tidak ada pengiriman "
        >
          <a-date-picker
            v-decorator="['date', {
              rules: [{
                required: true
              }]
            }]"
            style="width: 100%"
            :disabled-date="disabledDate"
          />
        </a-form-item>
      </div>
      <div class="col-12 col-md-6">
        <a-form-item :label="$t('purchase.shipping.method')">
          <!-- <a-select
            show-search
            v-decorator="['shipping_method', {
              rules: [{
                required: true
              }],
            }]"
            placeholder="Select Courier"
            style="width: 100%"
            @change="changeCourier"
          >
            <a-select-option :value="item.courier_services_code" v-for="(item, index) in courier" :key="index">
              {{ item.courier_name }} - {{ item.courier_services_name }}
            </a-select-option>
          </a-select> -->
          <a-select
            show-search
            v-decorator="['shipping_method', {
              rules: [{
                required: true
              }],
            }]"
            placeholder="Select Courier"
            style="width: 100%"
            @change="changeCourier"
          >
            <a-select-option :value="item.courier_services_code" v-for="(item, index) in courier" :key="index">
              <span v-if="item.courier_services_code !== 'SPU'"> {{ item.courier_name }} </span>
              <span v-if="item.courier_services_code === 'SPU'"> SELF PICKUP (Ambil Sendiri) </span>
            </a-select-option>
          </a-select>
        </a-form-item>
        <!-- <a-form-item>
          <a-checkbox @change="onChangeInsurance">
            Use Insurance
          </a-checkbox>
        </a-form-item> -->
      </div>
    </div>

    <div class="mt-3 row" v-if="!lastShippAddress && !changeShippData && createNewShipp">
      <div class="col-12 col-md-6">
        <a-form-item :label="$t('authForm.firstName')">
          <a-input
            v-decorator="['shipping_first_name', {
              rules: [{
                required: true
              }],
            }]"
            :placeholder="$t('authForm.firstName')"
          />
        </a-form-item>
      </div>
      <div class="col-12 col-md-6">
        <a-form-item :label="$t('authForm.lastName')">
          <a-input
            v-decorator="['shipping_name', {
              rules: [{
                required: true
              }],
            }]"
            :placeholder="$t('authForm.lastName')"
          />
        </a-form-item>
      </div>
      <div class="col-12 col-md-6">
        <a-form-item :label="$t('authForm.phoneNumber')">
          <a-input
            v-decorator="['shipping_phone', {
              rules: [{
                required: true,
                pattern: new RegExp(/[0-9\+]+/g, '')
              }],
            }]"
            :placeholder="$t('authForm.phoneNumber')"
          />
        </a-form-item>
      </div>
      <div class="col-12 col-md-6">
        <a-form-item :label="$t('authForm.emailAddress')">
          <a-input
            type="email"
            v-decorator="['shipping_email']"
            :placeholder="$t('authForm.emailAddress') + ' (optional)'"
          />
        </a-form-item>
      </div>
      <div class="col-12 col-md-12">
        <a-form-item :label="$t('authForm.address')" class="col-12 col-md-6">
          <a-input
            v-decorator="['shipping_address1', {
              rules: [{
                required: true
              }],
            }]"
            :placeholder="$t('authForm.address')"
          />
        </a-form-item>
      </div>
      <div class="col-12 col-md-6">
        <a-form-item :label="$t('authForm.country')">
          <a-select
            show-search
            v-decorator="['shipping_country_id', {
              rules: [{
                required: true
              }],
              initialValue: data && data.regionList.length && 'Indonesia'
            }]"
            :placeholder="$t('authForm.country')"
            @change="handleSelectCountry"
          >
            <a-select-option
              v-for="(item, index) in data.regionList"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>
      <div class="col-12 col-md-6">
        <a-form-item :label="$t('authForm.city')">
          <a-select
            show-search
            v-decorator="['shipping_city_id', {
              rules: [{
                required: true,
              }],
            }]"
            :placeholder="$t('authForm.placeCity')"
            @change="handleSelectCity"
          >
            <a-select-option
              v-for="(item, index) in purchase.cityListShipping"
              :value="item.label"
              :key="index"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>
      <div class="col-12 col-md-6">
        <a-form-item :label="$t('authForm.area')">
          <a-select
            show-search
            v-decorator="['shipping_area_id', {
              rules: [{
                required: true,
              }],
            }]"
            :placeholder="$t('authForm.placeArea')"
            @change="handleSelectArea"
          >
            <a-select-option
              v-for="(item, index) in purchase.areaListShipping"
              :value="item.label"
              :key="index"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>
      <div class="col-12 col-md-6">
        <a-form-item :label="$t('authForm.zip')">
          <a-input
            v-decorator="['shipping_codepos', {
              rules: [{
                required: true
              }],
            }]"
            :placeholder="$t('authForm.zip')"
          />
        </a-form-item>
      </div>
    </div>

    <div class="mt-3 row">
      <div class="col-12 col-md-6">
        <a-form-item label="Note">
          <a-textarea
            v-decorator="['note']"
            :auto-size="{ minRows: 3, maxRows: 7 }"
          />
        </a-form-item>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'purchase.update.shipping.detail',
  data: function () {
    return {
      isCourierChange: false,
      insurance: false,
      lastShippAddress: false,
      changeShippData: false,
      createNewShipp: false,
    }
  },
  props: ['courier', 'form'],
  computed: {
    ...mapState(['data', 'purchase', 'account']),

    billData () {
      return this.purchase.lastShippingAddress
    },
    allShippData () {
      return this.purchase.allShippingAddress
    },
  },
  methods: {
    changeCourier () {
      this.isCourierChange = true
    },
    onChangeInsurance (e) {
      this.insurance = e.target.checked
      this.isCourierChange = true
    },
    disabledDate (current) {
      return current && current < this.$moment().endOf('day')
    },
    handleChangeShippData () {
      if (!this.purchase.allShippingAddress.length) {
        this.$store.dispatch('purchase/GETUSERSHIPPINGADDRESS', {
          addr_key: 'all',
        })
          .then(() => {
            this.changeShippData = true
            this.createNewShipp = false
            this.lastShippAddress = false
          })
      } else {
        this.changeShippData = true
        this.createNewShipp = false
        this.lastShippAddress = false
      }
    },
    selectShipping (e) {
      const findDataShipping = this.allShippData.find(item => item.uuid === e)
      this.$store.commit('purchase/SET_LAST_SHIPPING_ADDRESS', findDataShipping)
      this.lastShippAddress = true
      this.changeShippData = false
      this.createNewShipp = false
    },
    handleCreateShippData () {
      this.lastShippAddress = false
      this.changeShippData = false
      this.createNewShipp = true
      this.$store.dispatch('data/GETCITYLIST', {
        value: 228,
        type: 'city',
        location: 'shipping',
      })
    },
    handleSelectCountry (e) {
      this.$store.dispatch('data/GETCITYLIST', {
        value: e,
        type: 'city',
        location: 'shipping',
      })
    },
    handleSelectCity (e) {
      const findCity = this.purchase.cityListShipping.find(item => item.label === e)
      this.$store.dispatch('data/GETCITYLIST', {
        value: findCity.value,
        type: 'area',
        location: 'shipping',
      })
    },
    handleSelectArea (e) {
      const findArea = this.purchase.areaListShipping.find(item => item.label === e)
      this.form.setFieldsValue({
        shipping_codepos: findArea.postcode,
      })
    },
  },
  watch: {
    isCourierChange (val) {
      if (val) {
        const findCourier = this.courier.find(item => item.courier_services_code === this.form.getFieldValue('shipping_method'))
        // hardcode SAP
        // const findCourier = this.courier.find(item => item.courier_services_code === 'UDRONS')
        this.$emit('updateCourier', {
          insurance: this.insurance,
          ...findCourier,
        })
        this.isCourierChange = false
      }
    },
    createNewShipp () {
      this.$emit('updateShippAddress', this.createNewShipp)
    },
    courier: {
      deep: true,
      handler: function () {
        this.changeCourier()
      },
    },
  },
  created () {
    this.$store.dispatch('purchase/GETUSERSHIPPINGADDRESS', {
      addr_key: 'last',
    })
      .then(({ data, isHaveLastShippAddress }) => {
        if (isHaveLastShippAddress) {
          this.lastShippAddress = isHaveLastShippAddress
        } else {
          // this.createNewShipp = true
          this.handleCreateShippData()
        }
      })
  },
}
</script>
