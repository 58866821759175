/* eslint-disable */
const req = require.context('./', true, /\w+\.(js)$/i);

const data = req.keys().reduce((prev, current) => {
  const module = req(current)

  if(current !== './index.js') {
    prev[module.name] = module
  }
  
  return prev
}, {});


function isCreditCard(method) {
  return method.split('_').includes('cc');
}


function get() {
  const keys = arguments;
  let result = data;

  for (let index in keys) {
    let key = keys[index];
    key = key === 'va' ? 'virtual_account' : key;

    result = result[key];
  }

  return result;
}

function getByDescription(description) {
  const parser = {
    'BCA VA': ['bca', 'va'],
    'BCA Bank Transfer': ['bca', 'transfer'],
    'BNI VA': ['bni', 'va'],
    'Mandiri VA': ['mandiri', 'va'],
    'BRI VA': ['bri', 'va'],
    'Permata VA': ['permata', 'va']
  }

  const id = parser[description];

  return id ? get(...id) : null;
}

export default {
  get,
  getByDescription,
  data,
  isCreditCard
};
