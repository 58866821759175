<template>
  <a-form :form="form">
    <div class="row">
      <div class="col-12 col-md-6">
        <a-form-item label="Diterima pada Tanggal:">
          <a-date-picker
            style="width: 75%"
            v-decorator="['date', {
              rules: [{
                required: true
              }]
            }]"
          />
        </a-form-item>
        <a-form-item label="Diterima oleh:">
          <a-input
            style="width: 75%"
            v-decorator="['received', {
              rules: [{
                required: true
              }]
            }]"
          />
        </a-form-item>
      </div>
      <div class="col-12 col-md-6 text-right">
        <a-button
          type="primary"
          htmlType="submit"
          class="text-center"
        >
          <strong> Konfirmasi Penerimaan </strong>
        </a-button>
      </div>
    </div>
  </a-form>
</template>

<script>
export default {
  name: 'purchase.update.confirm.GR',
  data: function () {
    return {
      form: this.$form.createForm(this),
    }
  },
}
</script>
