/* eslint-disable */
module.exports = {
  name: "snap_bca_va",
  description: "BCA Virtual Account (Snap)",
  image: "https://ik.imagekit.io/powerbiz/utils/bca2.png",
  // instructions: {
  //   "ATM BCA": [
  //     `Masukkan Kartu ATM BCA &amp; PIN`,
  //     `Pilih menu Transaksi Lainnya &gt; Transfer &gt; ke Rekening BCA Virtual Account`,
  //     `Masukkan nomor rekening BCA Virtual Account`,
  //     `Di halaman konfirmasi, pastikan detil pembayaran sudah sesuai seperti No VA, Nama, Perus/Produk dan Total Tagihan`,
  //     `Masukkan Jumlah Transfer sesuai dengan Total Tagihan`,
  //     `Ikuti instruksi untuk menyelesaikan transaksi`,
  //     `Simpan struk transaksi sebagai bukti pembayaran`
  //   ],
  //   "Internet Banking BCA": [
  //     `Lakukan log in pada aplikasi KlikBCA Individual (<a href="https://ibank.klikbca.com/" rel="noopener noreferrer nofollow">https://ibank.klikbca.com/</a>)`,
  //     `Masukkan User ID dan PIN`,
  //     `Pilih Transfer Dana &gt; Transfer ke BCA Virtual Account`,
  //     `Masukkan nomor rekening BCA Virtual Account`,
  //     `Masukkan jumlah yang ingin dibayarkan`,
  //     `Validasi pembayaran`,
  //     `Cetak nomor referensi sebagai bukti transaksi Anda`
  //   ],
  //   "M-BCA (BCA Mobile)": [
  //     `Lakukan log in pada aplikasi BCA Mobile`,
  //     `Pilih menu m-BCA, kemudian masukkan kode akses m-BCA`,
  //     `Pilih m-Transfer &gt; BCA Virtual Account`,
  //     `Masukkan Nomor Virtual Account`,
  //     `Masukkan jumlah yang ingin dibayarkan`,
  //     `Masukkan pin m-BCA`,
  //     `Pembayaran selesai. Simpan notifikasi yang muncul sebagai bukti pembayaran`
  //   ],
  //   "Kantor Bank BCA": [
  //     `Ambil nomor antrian transaksi Teller dan isi slip setoran`,
  //     `Serahkan slip dan jumlah setoran kepada Teller BCA`,
  //     `Teller BCA akan melakukan validasi transaksi`,
  //     `Simpan slip setoran hasil validasi sebagai bukti pembayaran`
  //   ]
  // }
};